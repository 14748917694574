<template>
  <b-container class="nopadding page view-all-posts">
    <h3>Blog Editor</h3>
    <router-link :to="{ name: 'New Blog Post' }"
      class="new-post button special noselect">New post</router-link>
    <b-table
      hover
      responsive
      show-empty
      emptyText="No posts created yet"
      :busy="isBusy"
      :items="posts"
      :fields="fields"
      selectable
      select-mode="single"
      class="mt-3">
      <template v-slot:table-busy>
        <div class="text-danger my-2 text-center">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(title)="data">
        <a :href="`posts/${data.item.permalink}/`" target="_blank">{{ data.value }}</a>
      </template>
      <template #cell(status)="data">
        {{ data.value | capitalize }}
      </template>
    </b-table>
    <div v-show="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
  </b-container>
</template>

<script>
import BlogPostsApi from '../../../api/BlogPosts';
import * as momentTemp from 'moment';

const moment = momentTemp.default;

export default {
  name: 'ViewAllBlogPosts',
  data() {
    return {
      error: false,
      isBusy: true,
      fields: [
        {
          key: 'title',
          label: 'Title',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'last_modified',
          label: 'Last modified',
          sortable: true,
          formatter: (value, key, item) => {
            const lastModified = moment(item.last_modified).tz(this.getLocalTimezone());
            return this.$options.filters.capitalize(lastModified.from());
          },
        },
      ],
      posts: [],
    };
  },
  methods: {
    getAllPosts() {
      BlogPostsApi
        .all()
        .then((response) => {
          this.isBusy = false;
          this.posts = response.data;
        })
        .catch((error) => {
          this.isBusy = false;
          this.error = this.parseError(error, 'Unable to retrieve blog posts');
        });
    },
  },
  created() {
    this.getAllPosts();
  },
};
</script>

<style scoped>
  h3 {
    text-transform: none;
  }

  .nopadding {
    position: relative;
    padding: 0;
  }

  .button.new-post {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1em;
    text-transform: none;
    height: 2.5em;
    line-height: 2.5em;
    padding: 0 0.8em;
  }

  .button.new-post.mobile {
    display: none;
  }

  @media screen and (max-width: 439px) {
    .button.new-post {
      display: none;
    }

    .button.new-post.mobile {
      display: block;
    }
  }
</style>